<template>
  <div>
    <c-card title="엑셀업로드" class="cardClassDetailForm">
      <template slot="card-button">
        <q-btn-group outline >
          <c-btn 
            v-if="editable" 
            label="LBLAPPLY" 
            icon="save"
            @btnClicked="acceptData" />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant
            required
            :editable="editable"
            label="LBLPLANT"
            name="plantCd"
            v-model="excelUploadInfo.plantCd">
          </c-plant>
        </div>
      </template>
    </c-card>
    <c-excel-upload 
      :editable="editable"
      :excelUploadInfo="excelUploadInfo"
      tabHeight="660px"
      gridHeight="630px"
    />
  </div>
</template>

<script>
export default {
  name: 'allbaro-result-excel-upload',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          plantCd: '',
        }
      },
    },
  },
  data() {
    return {
      excelUploadInfo: {
        rowKeys: ['envWasteAllbaroNo'], // 데이터들의 키 속성값
        taskClassCd: 'WASTE_ALLBARO_UPLOAD', // 엑셀업로드 양식 구분 값
        sheetNum: 0,
        columns: [[
          {
            name: 'envWasteAllbaroNo',
            field: 'envWasteAllbaroNo',
            label: '인계번호',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            required: true,
            name: 'allbaroDt',
            field: 'allbaroDt',
            label: '인계일자(*)',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            required: true,
            name: 'wasteType',
            field: 'wasteType',
            label: '폐기물종류(성상)(*)',
            align: 'left',
            style: 'width:300px',
            sortable: false,
          },
          {
            required: true,
            name: 'wasteVolume',
            field: 'wasteVolume',
            label: '위탁량(*)',
            align: 'center',
            style: 'width:120px',
            type: 'number',
            sortable: false,
          },
          {
            required: true,
            name: 'wasteVolumeUnit',
            field: 'wasteVolumeUnit',
            label: '단위(*)',
            align: 'center',
            style: 'width:60px',
            sortable: false,
          },
          {
            name: 'transportName',
            field: 'transportName',
            label: '운반자명',
            align: 'center',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'processName',
            field: 'processName',
            label: '처리자명',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'processMethod',
            field: 'processMethod',
            label: '처리방법',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'officeName',
            field: 'officeName',
            label: '관할관청',
            align: 'center',
            style: 'width:180px',
            sortable: false,
          },
        ]],
        data: [],
        deptCd: '',
        processCd: '',
        workplace: '',
      },
      changeItem: {
        plant: '',
        process: '',
        vendor: '',
      },
      editable: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    request() {
      return this.$store.getters.user.deptName + ' / ' + this.$store.getters.user.userName + ' / '  + this.$comm.getToday()
    },
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
    },
    acceptData() {
      if (this.excelUploadInfo.data) {
        let dataCntCheck = true;
        let errorDataCheck = false;
        if (!this.excelUploadInfo.data[0] || this.excelUploadInfo.data[0].length === 0) {
          dataCntCheck = false;
        }
        if (this.$_.findIndex(this.excelUploadInfo.data[0], (item) => { return item.error_message }) > -1) {
          errorDataCheck = true
        }
        if (!dataCntCheck) {
          window.getApp.$emit('ALERT', {
            title: 'LBLGUIDE', // 안내
            message: '업로드할 데이터가 없는 Sheet가 있습니다.\n\r데이터 확인 또는 엑셀을 업로드 하였는지 확인바랍니다.', // 업로드할 데이터가 없는 Sheet가 있습니다.\n\r데이터 확인 또는 엑셀을 업로드 하였는지 확인바랍니다.
            type: 'warning', // success / info / warning / error
          });
        } else {
          if (errorDataCheck) {
            window.getApp.$emit('CONFIRM', {
              title: 'LBLCONFIRM',
              message: '에러가 있는 데이터가 존재합니다.\n\r에러 있는 데이터를 제외한 나머지 데이터를 적용하시겠습니까?',
              // TODO : 필요시 추가하세요.
              type: 'info', // success / info / warning / error
              // 확인 callback 함수  
              confirmCallback: () => {
                this.$emit('closePopup', this.excelUploadInfo.data[0]);
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          } else {
            this.$emit('closePopup', this.excelUploadInfo.data[0], this.excelUploadInfo.plantCd);
          }
        }
      }
    }
  }
};
</script>
